import React from "react";

export default function CustomShareIcon({
  icon,
  url,
  styles,
}: {
  icon: React.ReactNode;
  url?: string;
  styles?: string;
}) {
  const handleConversion = () => {
    if (typeof window !== "undefined" && (window as any).gtag) {
      (window as any).gtag("event", "conversion", {
        send_to: "AW-16884991561/DVieCMW1i6EaEMmMsvM-",
        event_callback: function () {
          console.log("Conversion reported!");
        },
      });
    }
  };
  return (
    <div className="w-[280px] md:w-[380px] fixed bottom-[2%] right-[10px] md:bottom-[6px] md:right-[15px] z-999 group block">
      <div className="bg-white md:group-hover:bg-white md:invisible group-hover:visible  shadow-lg rounded-lg px-2 py-2 relative ping-once block transition duration-[800ms]">
        <div className="flex flex-col space-y-1">
          <div>
            <p className="text-[13px] md:text-[14px] lg:text-[16px] font-DmSan">
              Didn't find what you were looking for?
            </p>
            <p className="text-[13px] md:text-[14px] lg:text-[16px] font-DmSan">Please call us or WhatsApp us </p>
          </div>
          <p className="text-[#682CD8] text-[13px] md:text-[14px] lg:text-[16px] font-DmSan">+256200932870</p>
        </div>
      </div>
      <a target="_blank" rel="noreferrer" onClick={handleConversion} href={url}>
        <div style={{ position: "absolute", top: "14px", right: "0px" }} className={`${styles} absolute`}>
          {icon}
        </div>
      </a>
      {/* <div className={`${styles} block md:hidden`}>
        <a href={url}>{icon}</a>
      </div> */}
    </div>
  );
}
