import { useState, useEffect } from "react";
import Logo from "./Logo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logo } from "../constants";
import { Bars3Icon } from "@heroicons/react/24/solid";
import Button from "./Button";
import { Divide } from "./Divider";
import { Links } from "../data";

import { setCurrentItem } from "../features/app/appSlice";
import useApp from "../hooks/useApp";
// import { logout } from "../features/auth/authSlice";

export default function Navbar() {
  const navigate = useNavigate();
  const { dispatch, currentItem } = useApp();
  const { pathname } = useLocation();

  const [hoveredLinkId, setHoveredLinkId] = useState<number | null>(0);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [stickyNav, setStickyNav] = useState<boolean>(false);
  // const handleLogout = () => {
  //   dispatch(logout());
  //   navigate("/login");
  // };
  useEffect(() => {
    setCurrentItem("/home");

    window.addEventListener("scroll", () => {
      if (window.scrollY > 120) {
        setStickyNav(true);
      } else {
        setStickyNav(false);
      }
    });

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, [currentItem]);

  return (
    <div
      className={`${
        stickyNav ? "fixed top-0 left-0 right-0 bg-white h-[10%] z-[99999]" : "bg-transparent h-full"
      } max-w-[1460px] mx-auto px-5 sm:px-10 lg:px-30`}
    >
      <div className="w-full h-full flex flex-row items-center justify-between ">
        <Logo logo={logo} styles="h-20 w-30" />

        <div onClick={() => setShowMobileMenu(true)} className="block lg:hidden group">
          <Bars3Icon className="w-6 h-6 cursor-pointer" />
        </div>
        <div
          className={`top-0 h-screen w-full fixed p-8 ${
            !showMobileMenu ? "-right-full opacity-0" : "right-0 opacity-100"
          } bg-white border transform transition-all duration-[800ms] shadow-xl z-10`}
        >
          <div className="h-full flex flex-col justify-between">
            <div className="flex flex-col space-y-6">
              <div className="flex flex-col">
                {Links.map((l) => (
                  <div className="flex flex-col space-y-5" key={l.id}>
                    <Divide />
                    <Link
                      onClick={() => dispatch(setCurrentItem(l.link))}
                      to={l.link}
                      className={`cursor-pointer text-[15px] font-medium transition ease-in-out duration-[800ms] origin-left `}
                    >
                      <p className="text-[15px] font-DmSan font-bold">{l.title}</p>
                    </Link>
                    <Divide />
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col space-y-15">
              <div className="flex flex-col space-y-7">
                <a href="mailto:info@rentify.co.ug" className="flex flex-col items-start">
                  <p className="text-[14px] font-bold font-lato text-primary_color">Email us</p>
                  <p className="text-[14px] font-lato">info@rentify.co.ug</p>
                </a>
                <a href="tel:+256200932870" className="flex flex-col items-start">
                  <p className="text-[14px] font-bold font-lato text-primary_color">Quick Help</p>
                  <p className="text-[14px]">+256 200932870</p>
                </a>
              </div>
              <Button
                outline={true}
                text="Close Menu"
                onClick={() => setShowMobileMenu(false)}
                buttonStyle="py-4 text-[14px] font-bold text-primary_color rounded-md cursor-pointer border-primary_color"
              />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex items-center gap-4 2xl:gap-11">
          {Links.map((l) => (
            <div
              onClick={() => {
                dispatch(setCurrentItem(l.link));
                navigate(l.link);
              }}
              key={l.id}
              onMouseEnter={() => setHoveredLinkId(l.id)}
              onMouseLeave={() => setHoveredLinkId(null)}
              className={`px-5 2xl:px-6 py-2 rounded-[100px] cursor-pointer text-[15.2px] md:text-[16px] font-DmSan transition ease-in-out duration-[800ms] origin-left ${
                pathname === l.link || (l.link == "/home" && pathname == "/") ? "bg-nav_text_bg_color" : ""
              } ${hoveredLinkId === l.id ? "hover:bg-nav_text_bg_color" : ""}`}
            >
              <p className="text-[15px] font-bold">{l.title}</p>
            </div>
          ))}
        </div>
        <div className="hidden lg:flex items-center  gap-8 2xl:gap-11">
          <a href="mailto:info@rentify.co.ug" className="flex flex-col items-start">
            <p className="text-[14px] text-tertiary_color font-lato">Email us</p>
            <p className="text-[18px] font-bold font-lato">info@rentify.co.ug</p>
          </a>
          <a href="tel:+256200932870" className="flex flex-col items-start">
            <p className="text-[14px] text-tertiary_color font-lato">Quick Help</p>
            <p className="text-[18px] font-bold font-lato">+256 200932870</p>
          </a>
        </div>
      </div>
    </div>
  );
}
