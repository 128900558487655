import React, { useEffect, useState } from "react";
import {
  getProperties,
  getPropertyListingsBooked,
  getPropertyListingsDraft,
  getPropertyListingsLive,
  getPropertyListingsPending,
  getSearch,
} from "../features/property/propertyAction";
import useProperty from "../hooks/useProperty";
import useApp from "../hooks/useApp";

interface PaginationProps {
  totalPages: number;
  screen: string;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ totalPages, onPageChange, screen }) => {
  const { dispatch } = useProperty();
  const [currentPage, setCurrentPage] = useState(Number(sessionStorage.getItem("page")) || 1);
  const { userInfo, currentProperty } = useApp();

  const goToPage = (page: number) => {
    sessionStorage.setItem("page", page.toString());
    setCurrentPage(page);
    onPageChange(page);
  };

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <div
          key={i}
          className={`px-3 py-4 border ${currentPage === i ? "bg-tertiary_color" : "bg-white"} cursor-pointer`}
          onClick={() => goToPage(i)}
        >
          <p className="font-bold text-[13px] text-black">{i}</p>
        </div>
      );
    }
    return pages;
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  // TODO - Add logic to scroll user to top of properties after switching to new page
  //   });
  // }, []);

  useEffect(() => {
    switch (screen) {
      case "live":
        if (currentProperty === "Live property" && currentPage) {
          dispatch(
            getPropertyListingsLive({
              landlordId: userInfo?.id,
              page: currentPage,
            }) as any
          );
        }
        break;
      case "booked":
        if (currentProperty === "Booked property" && currentPage) {
          dispatch(
            getPropertyListingsBooked({
              landlordId: userInfo?.id,
              page: currentPage,
            }) as any
          );
        }
        break;
      case "draft":
        if (currentProperty === "Draft property" && currentPage) {
          dispatch(
            getPropertyListingsDraft({
              landlordId: userInfo?.id,
              page: currentPage,
            }) as any
          );
        }
        break;
      case "pending":
        if (currentProperty === "Pending property" && currentPage) {
          dispatch(
            getPropertyListingsPending({
              landlordId: userInfo?.id,
              page: currentPage,
            }) as any
          );
        }
        break;
      default:
        if (currentPage) {
          dispatch(getProperties(currentPage) as any);
        }
    }
    if (screen === "home") {
      if (currentPage) {
        dispatch(getProperties(currentPage) as any);
      }
    }
    // if (screen === "search") {
    //   if (currentPage) {
    //     dispatch(getSearch({ page: currentPage }) as any);
    //   }
    // }
  }, [currentPage, currentProperty, dispatch, screen, userInfo?.id]);

  return (
    <div className="hidden sm:flex items-center border rounded-md">
      <div
        className={`px-4 py-4 bg-[#F3F3F3] border transition duration-[800ms] transform hover:-translate-x-3 cursor-pointer ${
          currentPage === 1 ? "opacity-50 pointer-events-none" : ""
        }`}
        onClick={() => goToPage(currentPage - 1)}
      >
        <p className="font-bold text-[13px] text-[#BDBDBD]">Prev</p>
      </div>
      {renderPages()}
      <div
        className={`px-4 py-4 bg-white border transition duration-[800ms] transform hover:translate-x-3 cursor-pointer ${
          currentPage === totalPages ? "opacity-50 pointer-events-none" : ""
        }`}
        onClick={() => goToPage(currentPage + 1)}
      >
        <p className="font-bold text-tertiary_color text-[13px]">Next</p>
      </div>
    </div>
  );
};

export default Pagination;
